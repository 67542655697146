import React, {Component} from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { ROUTES, LAYOUT } from '../consts/index';
import styles from '../components/footer.module.css';
import Section from '../components/sections/section';

class Footer extends Component {
  render() { 
    const layout = LAYOUT.map((l,i) => 
        <li key={i}><Link className="" to={l.path}>{l.name}</Link></li>
    );

    return (
        <footer className={`page-footer text-center font-small info-color-dark ${styles['footer']}`}>
            <div className="rgba-stylish-strong">

                {/*Social icons*/}
                <div className="pt-4 pb-4 text-white">
                    {/*Facebook*/}
                    <a className="fb-ic link-light" href="https://www.facebook.com/Vansteelandtbvba/" target="_blank" rel="noopener noreferrer">
                        <span className="fab fa-facebook-f fa-md mr-4"> </span>
                    </a>
                    {/*Linkedin*/}
                    <a className="li-ic link-light" href="https://www.linkedin.com/company/vansteelandt-bvba/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                        <span className="fab fa-linkedin-in fa-md mr-4"> </span>
                    </a>
                    {/*Youtube*/}
                    <a className="li-ic link-light" href="https://www.youtube.com/channel/UCr-JmYC6cQq7ifobuWODDIQ" target="_blank" rel="noopener noreferrer">
                        <span className="fab fa-youtube fa-md mr-4"> </span>
                    </a>
                    {/*Web*/}
                    <a className="li-ic link-light" href="http://www.vansteelandt.be/" target="_blank" rel="noopener noreferrer">
                        <span className="fas fa-globe fa-md"> </span>
                    </a>
                </div>

                {/*Copyright*/}
                <div className={`${styles['footer-copyright']} text-center py-3`}>
                    <Section title="" fill={false} fullWidth={true} accent={false}>
                        <div className="container">
                            <div className="row text-white justify-content-center">
                                <div className="col-12 col-sm-2 col-lg-1">
                                    <p className="text-left">Policy:</p>
                                </div>
                                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                    <ul className="list-unstyled text-left">
                                        <li><Link className="" to={ROUTES.privacyPolicy.path}>Privacy policy</Link></li>
                                        <li><Link className="" to={ROUTES.cookiePolicy.path}>Cookie policy</Link></li>
                                        <li><Link className="" to={ROUTES.disclaimer.path}>Disclaimer</Link></li>
                                    </ul>
                                </div>
                                <div className="col-12 col-sm-2 col-lg-1">
                                    <p className="text-left">Layout:</p>
                                </div>
                                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                                    <ul className="list-unstyled text-left">
                                        { layout }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Section>

                    <div>
                        {/* Plan3D by Vansteelandt C year */}
                        Vansteelandt B.V. &copy; {new Date().getFullYear()}
                    </div>
                </div> 
            </div>
        </footer>
    )};
}

export default Footer