import React, {Component} from 'react';
import Section from '../components/sections/section';
import ImageSection from '../components/sections/image-section';

class ServiceDetailContent extends Component {
    render() { return (
        <>
            <Section title="CAD & GIS" subtitle={<span className="h6">
                Vansteelandt BVBA kan, voor maatwerk en het maken van workflows, beroep doen op eigen alsook externe specialisten. Er wordt gebruik gemaakt van diverse software in zowel CAD alsook GIS. <br/>
                Gebruik makend van eigen scripting en ETL toepassingen bouwen wij nieuwe, of bewerken we bestaande, databanken voor allerhande GIS-toepassingen alsook voor 3D CAD en 3D visualisaties.
                </span>}
                fullWidth={true} accent={false} fill={true}>
                <ImageSection 
                    images={[
                        {Source: `${process.env.PUBLIC_URL}/img/content/webservice/gis.jpg`, Alt:'Landmeter Netwerk'},
                    ]}
                    sectionId='webservice'
                    flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                    <span id="webservice" className="scroll-fix-item"></span>
                </ImageSection>
            </Section>
        </>
    )};
}

export default ServiceDetailContent
