import React, {Component} from 'react'
import styles from '../components/solution-card.module.css';

class SolutionCard extends Component {
  constructor(props) {
    super(props);
    this.image = props.image;
    this.link = props.action;
    this.title = props.title;
    this.text = props.text;
  }

  clicked = () => {
    window.location.href = this.link;
  }

  render() { return (
      <div className={`card card--clickable h-100`} onClick={this.clicked}>
        <div className={`view overlay no-pointer-events card-img-top ${styles["card-img-top--fill"]}`}
            style={{
              backgroundImage:  `url("${process.env.PUBLIC_URL}/img/services/${this.image}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className="mask rgba-white-slight"></div>
        </div>

        <div className={`card-body text-center no-pointer-events ${styles['card-body']}`}>
          <h4 className="card-title text-dark">{this.title}</h4>
          <p className="card-text text-dark text-muted">{this.text}</p>
          <div className={styles['card-footer']}>
            	<button className="btn btn-link p-2 m-0">Lees meer</button>
          </div>
        </div>
      </div>
  )};
}

export default SolutionCard
