import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'; 
import { ROUTES } from '../consts/index';
import ServiceDetailCADGIS from '../pages/serviceDetailCADGIS';
import ServiceDetail3DLaserscanning from '../pages/serviceDetail3DLaserscanning';
import ServiceDetailWarmtebeelden from '../pages/serviceDetailWarmtebeelden';
import ServiceDetailLandmeterNetwerk from '../pages/serviceDetailLandmeterNetwerk';
import ServiceDetailContent from '../pages/serviceDetailContent';


const ServiceDetail = () => {
  return (
    <section>
      <Switch>
        {/* Ga naar detail page van Landmeter Netwerk */}
        <Route exact strict path={ROUTES.serviceDetailLandmeterNetwerk}>
          <ServiceDetailLandmeterNetwerk />
        </Route>
        {/* Ga naar detail page van 3D Laserscanning */}
        <Route exact strict path={ROUTES.serviceDetail3DLaserscanning}>
          <ServiceDetail3DLaserscanning />
        </Route>
        {/* Ga naar detail page van Warmtebeelden */}
        <Route exact strict path={ROUTES.serviceDetailWarmtebeelden}>
          <ServiceDetailWarmtebeelden />
        </Route>
        {/* Ga naar detail page van CAD-GIS */}
        <Route exact strict path={ROUTES.serviceDetailCADGIS}>
          <ServiceDetailCADGIS />
        </Route>
        {/* Redirect naar services bij niet bestaande url */}
        <Route exact path={`${ROUTES.serviceDetail.to}*`}>
          <Redirect to={ROUTES.services.to} />
        </Route>
      </Switch>
    </section>
    
  );
};

export default ServiceDetail;
