import React, {Component} from 'react';
import Section from '../../components/sections/section';

class PrivacyPolicy extends Component {
    render() { return (
        <Section title="Privacy policy" fill={true}>
            <div className="section clearfix" id="top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <p> General Data Processing and Protection Policy</p>
                            <p><i>Version 2, made on 17/10/2022</i></p>
                            <p>&nbsp;</p>
                            <p>
                                We, at Plan3d by Vansteelandt, are aware that it is our responsibility to protect everyone's
                                privacy and the protection of personal data as well as possible. With this statement we want to
                                inform you as well as possible about what data we collect and how we collect, process and use
                                it.
                            </p>
                            <ol>
                                <li>
                                    <h4>What does this Privacy Statement apply to?</h4>
                                    <p>
                                        This Privacy Statement applies to the collection of visual material by Vansteelandt
                                        whereby Vansteelandt develops detailed 3D images of the real estate patrimony in Belgium
                                        via aerial images and makes these images available to third parties via a web platform
                                        "Plan3D", hereinafter also referred to as the "Pland3D Tool".
                                    </p>
                                    <p>
                                        This Privacy Statement also applies when you visit our website www.plan3d.be and/or you
                                        provide personal data, for example by contacting us further. More information about how
                                        we process your personal data as a customer or supplier can be found in the privacy
                                        policy stated on the website www.vansteelandt.be via this link 
                                        <a href='https://www.vansteelandt.be/legal/privacy.html' target='_blank' rel="noreferrer"> https://www.vansteelandt.be/legal/privacy.html</a>.
                                    </p>
                                </li>
                                <li>
                                    <h4>Who processes the personal data?</h4>
                                    <p>
                                        VANSTEELANDT BV (hereinafter also referred to as we or we) is responsible for the
                                        processing of the personal data: it partly determines the purpose and means for the
                                        operation of your personal data and can be contacted in the following ways:
                                    </p>
                                    <ul>
                                        <li>
                                            By post via: Vansteelandt BV, Vaartstraat 28/1, 9800 Deinze, Belgium (KBO
                                            0451.487.488)
                                        </li>
                                        <li>
                                            By phone on: +32 (0)9 243 81 10
                                        </li>
                                        <li>
                                            By e-mail: office@vansteelandt.be
                                        </li>
                                        <li>
                                            Via the websites: www.plan3d.be and www.vansteelandt.be
                                        </li>
                                    </ul>
                                    <p>
                                        Specific to our Plan3D-Tool and as soon as it is consulted by a user, the user should be
                                        regarded as responsible for the processing of the personal data. After all, the user
                                        determines the purpose and means for the processing of personal data at the time of
                                        consulting certain images via our Plan3D Tool. From that moment, Vansteelandt acts as
                                        Processor on behalf of its customer.
                                    </p>
                                    <p>
                                        Vansteelandt can also rely on data processors for the proper execution of its services.
                                        A processor can (further) process the personal data at the request or instruction of or
                                        on behalf of VANSTEELANDT, whereby it will be obliged to ensure the security and
                                        confidentiality of the personal data.
                                    </p>
                                </li>
                                <li>
                                    <h4>Which personal data are processed?</h4>
                                    <p>
                                        We only process personal data that are relevant or necessary for the purposes for which
                                        they are processed. Specifically for the compilation of our Plan3D-Tool, we process
                                        aerial images of real estate with map data into 3D plans and maps. We never process
                                        address details or other personal data. Pland3D-tool is therefore anonymous and does not
                                        link to persons nor does it contain any indication of who the real estate belongs to.
                                        Only users of Plan3D-tool who have additional data are able to link the data that
                                        Plan3D-tool processes to specific persons.
                                    </p>
                                </li>
                                <li>
                                    <h4>For what purposes do we process personal data?</h4>
                                    <p>
                                        Vansteelandt collects and processes aerial images and 3D images of real estate and map data solely for the purpose of compiling and developing the Plan3D-Tool.
                                        With this Vansteelandt strives to make detailed aerial images available to its customers. 
                                        These customers then decide for which purposes they wish to access the images.
                                    </p>
                                </li>
                                <li>
                                    <h4>On what legal basis are personal data processed?</h4>
                                    <p>Vansteelandt processes the aerial and 3d images on the basis of its legitimate interest.
                                        Taking into account that the aerial and 3d images are not publicly accessible and
                                        Vansteelandt ensures that Users of its Plan3d-Tool do not misuse the images and that
                                        they only consult them for well-defined (professional) purposes and on the basis of a
                                        valid legal basis, Vansteelandt is of the opinion that it has a legitimate interest in
                                        the processing of this data. After all, the processing is necessary for the purpose
                                        pursued by Vansteelandt with its Plan3D tool, as well as for the purpose pursued by the
                                        users of the Plan3d Tool.</p>
                                    <p>
                                        In addition, Vansteelandt counts on the fact that the effective users of the Plan3d-Tool
                                        also have a valid legal basis for the effective processing of the personal data.
                                        Vansteelandt hereby makes a means available to the users via its Plan3D-Tool whereby
                                        they must acknowledge that they have a valid legal basis and observe the necessary rules
                                        of use to avoid unauthorized use or access.
                                    </p>
                                </li>
                                <li>
                                    <h4>Do we share the personal data with others?</h4>
                                    <p>
                                        In addition to the users of Plan3D-Tool who can gain access to Plan3D-Tool, it is
                                        possible that Vansteelandt calls on (sub) processors for the execution of certain parts
                                        of its services (such as for example the hosting of the 3D images ). Vansteelandt will
                                        ensure that these (sub-)processors offer adequate guarantees with regard to the
                                        application of appropriate technical and organizational measures to ensure that the
                                        processing complies with the requirements of the laws and regulations on data
                                        protection, and imposes the necessary contractual obligations. them to ensure the
                                        protection of personal data.
                                    </p>
                                </li>
                                <li>
                                    <h4>How long is the personal data kept?</h4>
                                    <p>
                                        The personal data will be kept (i) as long as this is necessary or relevant for the
                                        intended purposes, (ii) for the duration to which we are legally obliged (e.g. in the
                                        context of warranty, accounting or employment obligations) or (iii) until such time a
                                        contractual relationship is in progress, or (iv) as long as there is a legal dispute or
                                        investigations may arise in relation to the services or products we provide (e.g. in the
                                        context of our ten-year liability).
                                    </p>
                                </li>
                                <li>
                                    <h4>What are your rights?</h4>
                                    <ol>
                                        <li type="A">
                                            <u>Right to information</u>
                                            <p>
                                                We wish to inform you as fully as possible about the processing of your personal
                                                data via this Privacy Statement. Your data will be processed for legitimate
                                                purposes in an appropriate, relevant, secure and proportionate manner, they will
                                                not be kept longer than necessary and always with the greatest possible
                                                integrity and confidentiality.
                                            </p>
                                        </li>
                                        <li type="A">
                                            <u>Right of access</u>
                                            <p>
                                                ou always have the right to check the lawfulness of any processing activity of
                                                your personal data and to ask us to provide you with information about the
                                                purposes of the processing, the categories of your personal data, the categories
                                                of recipients of your personal data, the retention period and the rights you can
                                                exercise.
                                            </p>
                                            <p>
                                                You also have the right to ask us for a copy of the personal data processed
                                                about you. This copy will in principle be provided to you free of charge unless
                                                your request would be unreasonable or excessive.
                                            </p>
                                        </li>
                                        <li type="A">
                                            <u>Right to improvement</u>
                                            <p>
                                                You have the right to correct incorrect data or to supplement incomplete data on
                                                simple request.
                                            </p>
                                        </li>
                                        <li type="A">
                                            <u>Right to erasure</u>
                                            <p>
                                                You have the right to request that we delete your personal data if there is no
                                                longer a valid reason to process it. You can exercise this right in the
                                                following cases:
                                            </p>
                                            <ul>
                                                <li>
                                                    Your personal data are no longer necessary to fulfill the pursued purpose;
                                                </li>
                                                <li>
                                                    You prove that we have processed your data unlawfully;
                                                </li>
                                                <li>
                                                    Your personal data must be deleted due to a legal obligation;
                                                </li>
                                                <li>
                                                    You withdraw your consent to the processing of your personal data (if the
                                                    processing is based on consent) and there is no other legal ground for
                                                    processing your data;
                                                </li>
                                            </ul>
                                            However, we reserve the right to refuse your request for deletion with reasons and
                                            cannot be held liable for the fact that deleted personal data is temporarily stored
                                            somewhere, invisible to us.
                                        </li>
                                        <li type="A">
                                            <u>Right to restriction of data processing</u>
                                            <p>
                                                You have the right to demand the restriction of data processing in one of the
                                                following circumstances:
                                            </p>
                                            <ul>
                                                <li>
                                                    You contest the accuracy of the personal data processed by us and we have
                                                    been given a reasonable period of time to verify the accuracy of the
                                                    personal data;
                                                </li>
                                                <li>
                                                    You prove that the processing by us was unlawful and request a restriction;
                                                </li>
                                                <li>
                                                    We no longer need the data, but you still need it for the exercise of a
                                                    legal claim;
                                                </li>
                                                <li>
                                                    You exercise your right to object (see below)
                                                </li>
                                            </ul>
                                        </li>
                                        <li type="A">
                                            <u>Right to object to data processing</u>
                                            <p>
                                                You have the right to object at any time to the processing of your personal data. 
                                                We will cease processing your personal data unless we can raise compelling grounds that outweigh your rights and freedoms.
                                                You will also be notified if this is the case.
                                            </p>
                                        </li>
                                        <li type="A">
                                            <u>Right to data portability</u>
                                            <p>
                                                You have the right to obtain the data you have provided to us on the basis of
                                                your consent in a structured, common and machine-readable form and to reuse it
                                                for other services and thus to transfer it to another controller, unless this is
                                                technically impossible.
                                            </p>
                                        </li>
                                        <li type="A">
                                            <u>Right not to be subject to automated decision-making</u>
                                            You have the right not to be subject to a fully automatic decision – without
                                            human intervention – if it significantly affects you or has legal consequences.
                                            To date, we declare that we do not subject you to such automated decision-making
                                            in any of the processing activities we carry out.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>How can you exercise your rights?</h4>
                                    <p>
                                        If you wish to exercise your rights, you must send us a written request and proof of
                                        identity through one of the following channels:
                                    </p>
                                    <ul className='mb-2'>
                                        <li>
                                            By registered letter to: VANSTEELANDT BV, Vaartstraat 28/1, 9800 Deinze, Belgium
                                        </li>
                                        <li>
                                            By e-mail to: dpo@vansteelandt.be
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h4>How is your personal data secured?</h4>
                                    <p>
                                        We always ensure that the necessary appropriate technical and organizational measures
                                        are taken to avoid loss or unauthorized access and use of your personal data. Our
                                        employees are bound to secrecy and we have also taken the necessary measures in the
                                        field of IT and technology.
                                    </p>
                                    <p>
                                        Despite these security measures taken, it may still happen that third parties succeed in
                                        circumventing these measures and obtain and/or use your personal data unlawfully. We can
                                        never be held liable in any way for this and are therefore in no way liable for any
                                        unlawful use of your personal data made by third parties.
                                    </p>
                                </li>
                                <li>
                                    <h4>Which cookies do we use?</h4>
                                    <p>
                                        When you visit our website, we collect information via Google Analytics to further
                                        optimize our website. This concerns the devices used, browser type with which our
                                        website is visited, and so on. At present, our website does not use cookies that may
                                        collect personal information about you. The use of cookies is limited by us to necessary
                                        or purely functional cookies that serve to make our website function correctly. If
                                        certain cookies do collect personal data about you in the future, your permission will
                                        be requested.
                                    </p>
                                    <p>
                                        More information about how to manage, install or delete cookies and how to do this on
                                        the particular web browser you use can be found in our cookie policy.
                                    </p>
                                </li>
                                <li>
                                    <h4>How can you file a complaint?</h4>
                                    <p>
                                        If you have certain complaints about the processing of your personal data, we thank you
                                        to first contact us directly via one of the contact channels described in article 9 so
                                        that we can resolve this for you.
                                    </p>
                                    <p>
                                        You also always have the right to file a complaint with the competent Data Protection
                                        Authority via this 
                                        <a href="https://www.privacycommission.be/contact" target="_blank" rel="noreferrer"> link</a>.
                                    </p>
                                </li>
                                <li>
                                    <h4>Can this policy change?</h4>
                                    <p>
                                        This privacy statement is tailored to the current situation, we regularly check whether
                                        it is still up-to-date. This privacy statement will be updated if necessary, at the top
                                        of this page you can always see when the privacy statement has been updated. You should
                                        therefore (re) view this Privacy Statement at regular intervals to ensure that you have
                                        made any changes. The continuation of your contractual relationship or use of our
                                        website or services will be regarded as acceptance of any changes made to this Privacy
                                        Statement.
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )};
}

export default PrivacyPolicy
