import React, { Component, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import Section from './sections/section';
import emailjs from 'emailjs-com';
import { uploadFileToFirebase } from '../consts/firebase-config';
import Popup from './popup';

class SolliciteerDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobRole: props.match.params.jobId || '', // props.jobRole || '',
            name: '',
            email: '',
            phone: '',
            message: '',
            cv: null,
            motivationLetter: null,
            showPopup: false,
            popupMessage: '',
            isSuccess: false,
        };
    }

    // De closePopup functie voor het sluiten van de popup
    closePopup = () => {
        this.setState({ showPopup: false });
    };

    // Functies voor het afhandelen van inputwijzigingen
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    // Functie voor het afhandelen van bestanduploads
    handleFileChange = (event) => {
        const { name, files } = event.target;
        this.setState({ [name]: files[0] });
    };

    // Functie voor het versturen van het formulier
    handleSubmit = async (event) => {
        event.preventDefault();
        const { jobRole, name, email, phone, message, cv, motivationLetter } = this.state;
        const cleanJobRole = jobRole.replace('Vacature_', '').replace(/_/g, ' ').trim();
        
        // part to send mail
        try {
            await emailjs.send('service_outlook', 'job_application', {
                jobrole: cleanJobRole,
                name,
                email,
                phone,
                message,
            }, process.env.REACT_APP_EMAILJS_PUBLICKEY);
            console.log('E-mail verzonden');
            this.setState({
                showPopup: true,
                popupMessage: 'Je sollicitatie is goed doorgekomen. Je hebt een bevestigingsmail gekregen in je mailbox. We contacteren je zo snel mogelijk.',
                isSuccess: true,
              });

        } catch (error) {
            console.error('Fout bij verzenden e-mail:', error);
            this.setState({
          showPopup: true,
          popupMessage: 'Je sollicitatie is niet goed doorgekomen. Stuur je sollicitatie rechtstreeks naar vacatures@vansteelandt.be',
          isSuccess: false,
        });
        }
        
        // part to upload files
        const dateTime = new Date().toISOString().replace(/[:.]/g, '-');
        try {
            if (cv) {
                const cvFileName = `CV_${name}_${dateTime}_${cv.name}`;
                await uploadFileToFirebase(cv, cvFileName);
            }

            if (motivationLetter) {
                const motivationFileName = `Motivatiebrief_${name}_${dateTime}_${motivationLetter.name}`;
                await uploadFileToFirebase(motivationLetter, motivationFileName);
            }
        } catch (error) {
            console.error('Fout bij uploaden naar Firebase Bucket:', error);
            this.setState({
                showPopup: true,
                popupMessage: 'Je CV en/of motivatiebrief is niet goed doorgekomen. Stuur dit bestand rechtstreeks naar vacatures@vansteelandt.be',
                isSuccess: false,
            });
        }
    };


    render() {
        const { jobId } = this.props.match.params;
        const jobTitle = jobId.replace(/_/g, ' ').trim();

        // Dynamische import voor de vacaturebeschrijving component
        const VacatureDescriptionComponent = lazy(() => import(`../components/vacatures/${jobId}.js`).catch(() => ({ default: () => <p>Vacaturebeschrijving niet beschikbaar.</p> })));

        return (
            <>
                {/* Toon de popup met dynamische boodschap en kleur als showPopup true is */}
                {this.state.showPopup && (
                <Popup
                    message={this.state.popupMessage}
                    onClose={this.closePopup}
                    isSuccess={this.state.isSuccess}
                />
                )}

                <Section centered={false} title={jobTitle} subtitle={<span className="h6"></span>}> 
                <h4>Vul onderstaande gegevens in om te solliciteren.</h4>
                <p>
                Of stuur je gegevens naar vacatures@vansteelandt.be. <br/>
                </p>
                <form id="solliciteer-form" onSubmit={this.handleSubmit}>
                <input
                    type="hidden"
                    name="jobRole"
                    value={this.state.jobRole}
                />
                
                <div className="form-group">
                    <label htmlFor="name">Naam *</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder="Voer uw naam in"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="email">E-mail *</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Voer uw e-mail in"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="phone">Telefoonnummer *</label>
                    <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder="Voer uw telefoonnummer in"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="message">Waarom solliciteer je bij ons? *</label>
                    <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                        rows="4"
                        placeholder="Schrijf hier je motivatie..."
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="cv">Upload je CV *</label>
                    <input
                        type="file"
                        className="form-control-file"
                        id="cv"
                        name="cv"
                        onChange={this.handleFileChange}
                        accept=".pdf"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="motivationLetter">Upload je motivatiebrief</label>
                    <input
                        type="file"
                        className="form-control-file"
                        id="motivationLetter"
                        name="motivationLetter"
                        onChange={this.handleFileChange}
                        accept=".pdf"
                    />
                </div>
                
                <input type="submit" className="btn btn-dark p-2 m-0" value="Solliciteer nu" />
                </form>
                <br/>
                </Section>

            </>
        );
    }
}

export default withRouter(SolliciteerDetail);
