import React, { Component } from 'react';

import Section from './section';
import ContactInfo from '../contact-info';
import GoogleMap from '../google-map';

class ContactSection extends Component {
    render() { return (
    <Section title="Contact" subtitle="Feel free to contact us" fill={false} accentDark={true} fullWidth={true}>
        <span id="contact" className="scroll-fix-item"></span>
        <div className="row">
            <div className="col-md-7 text-center mx-auto p-3">
                <ContactInfo />
            </div>
        </div>                
        <div className="row no-gutters m-minus-4 mt-4">
            <div className="col-12 p-0 m-0 map-container-section">
                <GoogleMap className="text-dark" />
            </div>
        </div>
    </Section>
    )}
}

export default ContactSection
