import React, {Component} from 'react';
import Section from '../components/sections/section';
import ImageSection from '../components/sections/image-section';

class ServiceDetailLandmeterNetwerk extends Component {
    render() { return ( 
        <>
            <Section title="Landmeter Netwerk" subtitle={<span className="h6">
                Traditionele Landmeetkunde met Total Station en/of (RTK) GPS. Opmaken van processen-verbaal van opmeting, afpaling, splitsing. M.a.w. de stukken nodig voor een akte bij de notaris.<br/>
                Detailopmetingen dienstig voor het bepalen van zettingen, volumeberekening, positiebepaling of allerlei opmetingsplannen.<br/>
                Gedetailleerde metingen en inventarisatie van wegen, waterwegen, gasleidingen, hoogspanningslijnen, enz...<br/>
                Voorbeelden van eindproducten zijn: gedetailleerde plannen op schaal, dwarssneden en lengteprofielen, bouwplannen en as-built plannen, enz...<br/>
                Deze plannen kunnen worden gebruikt als basis voor ontwerp, engineering en GIS.<br/>
                </span>}
                fullWidth={true} accent={false} fill={true}>
                <ImageSection 
                    images={[
                        {Source: `${process.env.PUBLIC_URL}/img/content/webservice/meten_michael.jpg`, Alt:'Landmeter Netwerk'},
                    ]}
                    sectionId='webservice'
                    flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                    <span id="webservice" className="scroll-fix-item"></span>
                </ImageSection>
            </Section>
        </>
    )};
}

export default ServiceDetailLandmeterNetwerk
