import React, { Component } from 'react';
import './popup.css';  // Zorg ervoor dat het juiste pad wordt gebruikt


class Popup extends Component {
  render() {
    const { message, onClose, isSuccess } = this.props;

    return (
        <div className="popup-overlay" onClick={onClose}>
        <div className={`popup-card ${isSuccess ? 'popup-success' : 'popup-error'}`} onClick={(e) => e.stopPropagation()}>
          <div className="popup-body">
            <i className={`popup-icon ${isSuccess ? 'fas fa-check-circle' : 'fas fa-times-circle'}`}></i>
            <h5 className="popup-title">{isSuccess ? 'Succes!' : 'Foutmelding'}</h5>
            <p className="popup-message">{message}</p>
          </div>
          <div className="popup-footer">
            <button className="popup-button" onClick={onClose}>Sluiten</button>
          </div>
        </div>
      </div>      
    );
  }
}

export default Popup;
