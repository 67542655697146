import React, {Component} from 'react'
import HeaderCarouselSlide from '../components/header-carousel-slide';
import styles from '../components/header-carousel.module.css';
import DataCoverage from '../components/data-coverage';

class HeaderCarousel extends Component {
    render() { return (
    <div id="header-carousel" className={`carousel slide carousel-fade ${styles["header-carousel"]} no-pause`} data-ride="carousel" data-interval="10000">
      <ol className="carousel-indicators">
        <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
        <li data-target="#header-carousel" data-slide-to="2"></li>
        <li data-target="#header-carousel" data-slide-to="2"></li>
        <li data-target="#header-carousel" data-slide-to="3"></li>
        <li data-target="#header-carousel" data-slide-to="4"></li>
      </ol>
      <div className={`${styles["carousel-slides"]}`} role="listbox">
        <HeaderCarouselSlide image="red2.jpg" skipLogo active={true} />
        <HeaderCarouselSlide image="IMG_1964.jpg" skipLogo active={false} />
        <HeaderCarouselSlide image="grb_luchtfoto_a.jpg" skipLogo active={false} />
        <HeaderCarouselSlide image="green1.jpg" skipLogo active={false} />
        <HeaderCarouselSlide image="purple2.jpg" skipLogo active={false} />
      </div>
      <a className="carousel-control-prev" href="#header-carousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon carousel-control-large" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#header-carousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon carousel-control-large" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>

    </div>
    )};
}

export default HeaderCarousel
