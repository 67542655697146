import React, {Component} from 'react';
import VideoSection from '../components/sections/video-section';
import Section from '../components/sections/section';
import ImageSection from '../components/sections/image-section';

class ServiceDetail3DLaserscanning extends Component {
    render() { return (
        <>
            <Section title="3D Laserscanning" subtitle={<span className="h6">
                        Met meer dan 15 jaar ervaring in scanning in België is Vansteelandt de aangewezen partner. Wij
                        beschikken over 5 laserscanners van verschillende types. Hiermee werden reeds meer dan 3500 gebouwen, industriële sites of
                        andere bouwwerken gescand. Meer dan 4.000.000 m² vloeroppervlak werd reeds in kaart gebracht en
                        geleverd als 2D CAD bestanden, 3D Revit/MEP modellen en/of 3D visualisaties. <br/>
                        3D Laserscanning is veelal goedkoper dan traditioneel opmeten en het biedt een veelvoud aan
                        detail. Miljoenen punten, die de gehele ruimte in detail weergeven, worden gescand met een hoge nauwkeurigheid en op een veel
                        snellere manier dan een paar specifieke punten met een totaal station. Onze specialisten brengen de reële
                        situatie bij de klant.<br/>
                        Voor het Gemeenschapsonderwijs hebben we ongeveer 800 sites gescand met een variëteit aan
                        gebouwen.<br/>
                        <a href="https://www.demorgen.be/binnenland/1-5-miljoen-euro-om-school-gebouwen-in-kaart-te-brengen-bc4986a5/"
                            target="_blank" alt="artikel GO">
                            Een artikel uit De Morgen.</a><br/><br/>
                        3D laserscanning is een product dat een oplossing biedt voor erfgoed, facilitymanagement,
                        architecten en ingenieurs.
                </span>}
                fullWidth={true} accent={false} fill={true}>
                <ImageSection 
                    images={[
                        {Source: `${process.env.PUBLIC_URL}/img/content/webservice/3dscanbg.jpg`, Alt:'Landmeter Netwerk'},
                    ]}
                    sectionId='webservice'
                    flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                    <span id="webservice" className="scroll-fix-item"></span>
                </ImageSection>
                <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/BYaevJn4ESk"
                            frameborder="0" allowfullscreen width="100%" height="400px"></iframe>
                </div>
            </Section>
            <VideoSection 
                link="https://www.youtube.com/embed/BYaevJn4ESk" 
                contentTitle="Data of Flanders"
                contentText='With our own Special Mission Platform aircrafts, we continuously capture up-to-date 3D field data. In addition to orthomosaics (GSD 3-4 cm), our Plan3D product also includes oblique oriented photo images. Every year large areas within the Benelux are fully covered. Areas where no data is available yet, we can plan to be surveyed in short period. Based on a large number of images from different angles, and/or LIDAR data recorded at the same time, an accurate point cloud is built. Next to the Benelux, we can also operate Plan3D in other European countries.
                Plan3D offers the opportunity to fill all needs concerning a basic terrain survey, GIS update, up to 3D Virtual Reality modelling. Plan3D can be consulted with Web viewers and the orthomosaics can be used in GIS. Due to its high accuracy, Plan3D is a reliable reference. This makes Plan3D the ultimate product for analysis or for mapping. Plan3D definitely is the cheapest and fastest way to get accurate and actual 3D site information.
                With Plan3D we bring project sites in 3D to your office, which creates the next major step towards virtual surveying. Due to its detail, combined with high accuracy, Plan3D opens many possibilities for a large variety of projects to quickly provide the necessary "measurement". Do you need a representation of the actual situation or a mapping in any way, first ask what is possible based on Plan3D. With Plan3D we can offer a solution for object inventory, road infrastructure, rivers and canals, buildings and other structures, vegetation, utilities and energy-related assessment. The detailed 3D database offers the possibility to perform all types of 3D engineering in virtual reality.'
            />
        </>
    )};
}

export default ServiceDetail3DLaserscanning
