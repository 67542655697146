import React from 'react';

const VideoSection = ({ contentTitle, contentText, link, accent, frameTitle }) => {
    return (
        <div className={`${accent ? "section-accent" : ""} w-100`}>
            <div className="container">
                <div className="row no-gutters align-items-center">
                    <div className="col-md-8 col-lg-7 order-md-2">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title={frameTitle} className="embed-responsive-item" src={link} allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-5 mx-auto d-flex align-items-center">
                        <div className="p-4">
                            { contentTitle ? <h3 className="font-weight-normal mb-4">{contentTitle}</h3> : <></> }
                            {contentText}
                        </div>
                    </div>
                </div>
            </div>
        </div>
)};

export default VideoSection
