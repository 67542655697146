import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ROUTES } from '../consts/index';
import Home from '../pages/home';
import Disclaimer from '../pages/legal/disclaimer';
import CookiePolicy from '../pages/legal/cookiePolicy';
import PrivacyPolicy from '../pages/legal/privacyPolicy';
import ServiceDetail from '../containers/serviceDetail';
import ClientZone from '../pages/clientZone';
import PrivateRoute from '../components/authentication/PrivateRoute'
import JobDetail from '../components/job-detail';
import SolliciteerDetail from '../components/solliciteer-detail';


const RoutingContent = () => {
  /*
  const location = useLocation();

  useEffect(() => {
    // Verzendt een paginaweergave naar Google Analytics bij elke routeverandering
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search
    });
  }, [location]); // De useEffect zal reageren op routewijzigingen
  */

  return (
    <section>
      <Switch>
        {/* Ga naar home page */}
        <Route exact strict path={ROUTES.home.page}>
          <Home />
        </Route>
        {/* Ga naar solution detail page */}
        <Route path={ROUTES.serviceDetail.path}>
          <ServiceDetail />
        </Route>

        {/* Toevoegen van de nieuwe JobDetail route */}
        <Route path={ROUTES.jobDetail.path}>
          <JobDetail />
        </Route>

        {/* Toevoegen van de nieuwe SolliciteerDetail route */}
        <Route path={ROUTES.solliciteerDetail.path}>
          <SolliciteerDetail /> 
        </Route>

        {/* Ga naar klantenzone: Automatisch door Auth0 configuratie
        <PrivateRoute path={ROUTES.clientZone} component={ClientZone} />
        */}

        <Route exact strict path={ROUTES.privacyPolicy.to}>
          <PrivacyPolicy />
        </Route>
        {/* Ga naar de legal cookie policy */}
        <Route exact strict path={ROUTES.cookiePolicy.to}>
          <CookiePolicy />
        </Route>
        {/* Ga naar de legal disclaimer */}
        <Route exact strict path={ROUTES.disclaimer.to}>
          <Disclaimer />
        </Route>
        {/* Redirect naar home bij niet bestaande url */}
        <Route exact path="*">
          <Redirect to={ROUTES.home.page} />
        </Route>
      </Switch>
    </section>
  );
};

export default RoutingContent;
