// utils/gtag.js
export const initializeGTag = (trackingId) => {
    if (!trackingId) return;
  
    // Voeg het Google Tag script toe aan de <head>
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    scriptTag.async = true;
    document.head.appendChild(scriptTag);
  
    // Initialiseer gtag.js
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', trackingId);
  };
  