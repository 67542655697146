import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react';
import styles from '../components/google-map.module.css';


class GoogleMap extends Component {
    constructor(props) {
        super(props);

        this.center = {
            lat: 50.989183,
            lng: 3.531983
        };
        this.zoom = 15;
    }

    renderMarkers(map, maps) {        
        let marker = new maps.Marker({
            position: { lat: this.center.lat, lng: this.center.lng },
            map,
            title: 'Vansteelandt'
        });

         // const contentString = 
        // '<div id="content">' +
        // '   Vansteelandt b.v.' +
        // '</div>';
        // const infowindow = new maps.InfoWindow({
        //     content: contentString
        // });
        
        // infowindow.open(map, marker);
        // marker.addListener("click", () => {
        //     infowindow.open(map, marker);
        // });

        return marker;
    }

    render() { return (
        <div className={`${styles["map-container-section"]} ${this.props.className}`} id="map">
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals={true}
                bootstrapURLKeys={{ key: "AIzaSyAo6HlTwJqFhXe1nV2RAeyZyhc9V9TMj0s" }}
                defaultCenter={this.center}
                defaultZoom={this.zoom}
                onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
                options={{
                    // scrollwheel: false,
                    zoomControl: false,
                    //gestureHandling:'greedy',
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,                  
                    styles: [
                        { elementType: "geometry", stylers: [{ color: "#323945" }] },
                        { elementType: "labels.text.stroke", stylers: [{ color: "#323945" }] },
                        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                        {
                          featureType: "administrative.locality",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#ffffff" }]
                        },
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off"  }]
                        },
                        {
                          featureType: "poi",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#ffffff", visibility: "off"  }]
                        },
                        {
                          featureType: "road",
                          elementType: "geometry",
                          stylers: [{ color: "#38414e" }]
                        },
                        {
                          featureType: "road",
                          elementType: "geometry.stroke",
                          stylers: [{ color: "#323945" }]
                        },
                        {
                          featureType: "road",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#9ca5b3" }]
                        },
                        {
                          featureType: "road.highway",
                          elementType: "geometry",
                          stylers: [{ color: "#746855" }]
                        },
                        {
                          featureType: "road.highway",
                          elementType: "geometry.stroke",
                          stylers: [{ color: "#1f2835" }]
                        },
                        {
                          featureType: "road.highway",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#ffffff" }]
                        },
                        {
                          featureType: "transit",
                          elementType: "geometry",
                          stylers: [{ color: "#323945" }]
                        },
                        {
                          featureType: "transit.station",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#d59563" }]
                        },
                        {
                          featureType: "water",
                          elementType: "geometry",
                          stylers: [{ color: "#17263c" }]
                        },
                        {
                          featureType: "water",
                          elementType: "labels.text.fill",
                          stylers: [{ color: "#515c6d" }]
                        },
                        {
                          featureType: "water",
                          elementType: "labels.text.stroke",
                          stylers: [{ color: "#17263c" }]
                        }
                    ]
                }}
                >
            </GoogleMapReact>
        </div>
    )};
}

export default GoogleMap
