import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getStorage, ref, uploadBytes } from "firebase/storage"; // Import Storage functies


// Web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Anonymous log in
const auth = getAuth();
const storage = getStorage(app); // Firebase Storage referentie
const database = getDatabase(app);

signInAnonymously(auth)
.then(() => {
    // Signed in..
})
.catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(`Error ${errorCode}: ${errorMessage}`);
});

// Function to upload files to Firebase Storage
const uploadFileToFirebase = async (file, fileName) => {
  try {
    const storageRef = ref(storage, fileName); // Maak een referentie naar de opslaglocatie
    await uploadBytes(storageRef, file); // Upload bestand naar Firebase Storage
    console.log(`${fileName} succesvol geüpload!`);
  } catch (error) {
    console.error('Fout bij het uploaden:', error);
  }
};

// Export de benodigde functies en variabelen
export { auth, database, storage, uploadFileToFirebase };

