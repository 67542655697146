// Path is used for links (href / button) and to is used in routing component
const ROUTES = {
  home: { page: "/", header: "/#home" },
  services: { path: "/#services", to: "#services" },
  news: { path: "/#news", to: "#news" },
  about: { path: "/#about", to: "#about" },
  jobs: { path: "/#jobs", to: "#jobs" },
  contact: { path: "/#contact", to: "#contact" },
  
  serviceDetail: { path: "/services/:type", to: "/services/" },
  jobDetail: { path: "/job/:jobId", to: "/job/" },
  solliciteerDetail: { path: "/solliciteer/:jobId", to: "/solliciteer/" },


  /* Service Detail Pages */
  serviceDetailLandmeterNetwerk: "/services/landmeter-netwerk",
  serviceDetail3DLaserscanning: "/services/3D-laserscanning",
  serviceDetailWarmtebeelden: "/services/warmtebeelden",
  serviceDetailCADGIS: "/services/CAD-GIS",
  serviceDetailTerreinopmeting: "/services/terreinopmeting",
  serviceDetailContent: "/services/landmeter-netwerk",

  /* Legal */
  privacyPolicy: { path: "/legal/privacy#top", to: "/legal/privacy" },
  cookiePolicy: { path: "/legal/cookies#top", to: "/legal/cookies" },
  disclaimer: { path: "/legal/disclaimer#top", to: "/legal/disclaimer" },
};

const LAYOUT = [
  {path: ROUTES.about.path, name: "About us"},
  {path: ROUTES.services.path, name: "Services"},
  {path: ROUTES.jobs.path, name: "Jobs"},
  {path: ROUTES.contact.path, name: "Contact"},
];

export { ROUTES, LAYOUT };
