// utils/gtag.js
export const initializeCookieBot = (CBID) => {
    if (!CBID) return;
  
    const scriptTag = document.createElement('script');
    scriptTag.id = 'Cookiebot';
    scriptTag.src = 'https://consent.cookiebot.com/uc.js';
    scriptTag.setAttribute('data-cbid', CBID); // Vervang met jouw unieke ID
    scriptTag.setAttribute('data-blockingmode', 'auto');
    scriptTag.type = 'text/javascript';
    document.head.appendChild(scriptTag);

    return () => {
        // Verwijder het script als de component unmount
        const existingScript = document.getElementById('Cookiebot');
        if (existingScript) {
          document.head.removeChild(existingScript);
        }
      };

  };
  