import React, {Component} from 'react';
import { ROUTES } from '../../consts/index';
import { HashLink as Link } from 'react-router-hash-link';
import Section from '../../components/sections/section';

class CookiePolicy extends Component {
    componentDidMount () {
        // Create script tag:
        // <script id="CookieDeclaration" src="https://consent.cookiebot.com/af54b023-f33b-4247-9bf6-a370a67880e1/cd.js" type="text/javascript" async></script>
        const domainId = 'af54b023-f33b-4247-9bf6-a370a67880e1';
        const jsCookies = document.createElement("script");
        jsCookies.src = `https://consent.cookiebot.com/${domainId}/cd.js`;
        jsCookies.async = true;
        jsCookies.id = 'CookieDeclaration';
        document.querySelector('#CookieDeclarationContainer').appendChild(jsCookies);
    }

    render() { return (
            <Section title="Cookie policy" fill={true}>
                <div className="section clearfix" id="top">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <p><i>Version 1, made on 01/08/2021</i></p>
                                <p>&nbsp;</p>
                                <p>
                                    This policy sets out how the company handles the personal data it collects and obtains through
                                    the use of cookies and similar technologies. This model must always be adapted to each concrete
                                    situation, whereby further advice is recommended. Any liability for use of the model is
                                    excluded.
                                </p>
                                <h3>Cookie policy</h3>
                                <p>
                                    Vansteelandt BV attaches great importance to your privacy and the protection of your personal
                                    data. In this cookie policy we explain how we handle the personal data we collect via cookies,
                                    what we do with it and how we process this data. We believe it is important to create and
                                    maintain an environment in which the users of our online environment can be sure that their
                                    information is not misused.
                                </p>
                                <h4>
                                    1. Scope
                                </h4>
                                <p>
                                    This website and associated cookies are managed by Vansteelandt BV with registered office at
                                    Vaartlaan 28, box 1, 9800 Deinze and KBO number 0451.487.488. We act as a controller when we
                                    process the personal data of the users of our online environment by means of cookies.
                                </p>
                                <p> We are in compliance with applicable data protection regulations, such as the General Data
                                    Protection Regulation No. 2016/679 (AVG or GDPR) and national data protection law. These
                                    regulations relate to the protection of your personal data and grant you rights with regard to
                                    that personal data.
                                </p>
                                <p>
                                    The purpose of this policy is to indicate which personal data we collect through cookies and how
                                    we use your data after you have visited our website via a desktop computer, tablet, smartphone
                                    or other medium on which the cookies are installed.
                                </p>
                                <h4>
                                    2. What are cookies?
                                </h4>
                                <p>
                                    Cookies are small text files that contain small pieces of information and store information,
                                    such as your language preference, when you visit our website. These cookies are stored on
                                    your computer, tablet or smartphone when you visit our website. Cookies may contain
                                    personally identifying information, but this is not always the case.
                                </p>
                                <p>
                                    When you visit the website again at a later time, this cookie will send information to the
                                    website so that the website recognizes your browser and can, for example, remember your
                                    language preference. Some cookies also make it possible to enable certain functions on a
                                    website or to measure how website visitors use our website so that we can use this
                                    information to optimize the website.
                                </p>
                                <p>
                                    Cookies often have an expiration date with the result that they are automatically deleted
                                    after a certain time. Some cookies are deleted immediately after closing the browser, such
                                    as session cookies, while others remain on your device for a longer period of time.
                                </p>
                                <h4>
                                    3. What types of cookies do we use and why?
                                </h4>
                                <p>
                                    <em>Strictly necessary / essential and functional cookies</em><br />
                                    Strictly necessary cookies are essential to be able to navigate the website and use the
                                    functions offered. Functional cookies remember the choices you have made, such as the
                                    country from which you visit the website, language choice and search parameters. Without
                                    those cookies, requested services such as remembering your login details or the items of a
                                    shopping basket cannot be provided. These cookies therefore ensure that we can offer you a
                                    more pleasant experience that better matches your choices. These cookies cannot track your
                                    browsing activity on other websites.<br />
                                    We can install these cookies without asking your permission.
                                </p>
                                <p>
                                    <em>Cookies for analytical and statistical purposes</em><br />
                                    These cookies collect anonymous information about how people use our website. We use these
                                    cookies to find out how customers arrive at our website, how they surf or use our website
                                    and what can be improved such as navigation, shopping experience and marketing campaigns.
                                    The data stored by these cookies does not contain any personal information that could
                                    identify you.
                                </p>
                                <p>
                                    We use a “first party” analysis tool installed on our own servers. This means that we have
                                    full control over the information collected through these cookies. No data is sent to a
                                    third party. As a result, we can assure you that this information will not be used for any
                                    other purpose. Your prior consent is requested for the use of these "first party" cookies
                                    for analytical purposes. These cookies are therefore only installed on your device after you
                                    have given your consent.
                                </p>
                                <p>
                                    We also use third-party services such as eg. Google Analytics to collect information about
                                    visitors to our website. This information is aggregated to include the number of visits,
                                    average visit duration, the viewed pages, etc. to be determined. We use this information to
                                    measure website usage and to
                                    improve the content and value of our website. More information about how Google processes
                                    your information can be found on – the Google Analytics webpage. These "third party" cookies
                                    for analytical purposes are installed on your device when you use the website. If you do not
                                    wish this, you can block and delete these cookies in your browser.
                                </p>
                                <p>
                                    List of cookies used
                                </p>

                                <div id='CookieDeclarationContainer'>
                                    {/* <script id="CookieDeclaration" src="https://consent.cookiebot.com/af54b023-f33b-4247-9bf6-a370a67880e1/cd.js" type="text/javascript" async></script> */}
                                </div>

                                <h4>
                                    4. How is your data used and shared?
                                </h4>
                                <p>We may process and share the information we obtain through cookies with:</p>
                                <ul>
                                    <li>companies belonging to the group and/or with subsidiaries of the group; or</li>
                                    <li>with any third party with whom you have requested us to share your personal data or with
                                        organizations with which you have consented to share information, such as LinkedIn or
                                        other social media outlets acting as data controllers; or</li>
                                    <li>third parties who assist us with our products and services. Some examples of third-party
                                        activities include data analytics, marketing support providers, and web viewer creators.
                                        These companies have access to the information processed through the cookies, but only
                                        when strictly necessary to carry out their activities. They may not use your data for
                                        other purposes.</li>
                                </ul>
                                <h4>
                                    5. Transfer of your personal data
                                </h4>
                                <p>
                                    Vansteelandt BV does not pass on your personal data to third countries outside the European
                                    Economic Area, except for its cooperation with google in the context of Google Analytics.
                                </p>
                                <p>
                                    We may transfer your personal data to third parties located in third countries (outside the
                                    European Economic Area (EEA)), solely in the context of processing by Google Analytics. This
                                    transfer of data outside the EEA is lawful if the recipient of the data is located in a
                                    country that offers an adequate level of protection following an adequacy decision by the
                                    European Commission. Some of those countries may not have equivalent data protection laws to
                                    protect the use of your personal data. In this case, we have investigated whether
                                    appropriate precautions such as within the EU are possible, e.g. by providing standard
                                    contractual clauses. In specific cases, we request your prior consent to transfer your
                                    personal data outside the EEA. For more information about the transfer of data, please see
                                    our privacy policy.
                                </p>
                                <h4>
                                    6. Deletion of cookies and additional information about cookies
                                </h4>
                                <p>
                                    If you have given us your consent by clicking on the relevant button of the cookie banner,
                                    you can withdraw this consent at any time. You can do this by deleting the stored cookies
                                    via your browser.
                                </p>
                                <p>
                                    Some cookies are already deleted when you close your browser (session cookies). Others
                                    remain on your device until they expire or until you delete them from the cache (persistent
                                    cookies or tracking cookies) as these cookies allow us to remember things about you when you
                                    visit our website again.
                                </p>
                                <p>
                                    By deleting our cookies or disabling future cookies, certain parts or functions of our
                                    website may not be accessible.
                                </p>
                                <p>
                                    If you want to delete cookies that are already on your computer, please consult the help and
                                    support function of your internet browser where you will find instructions to find the file
                                    or folder where cookies are stored. On most browsers, the help function in the toolbar also
                                    provides information on how to prevent your browser from accepting new cookies, what to do
                                    for your browser to notify you when you receive a new cookie, or how to disable cookies
                                    completely. Please note that if you choose to disable cookies, some personalized services
                                    may no longer be available.
                                </p>
                                <p>
                                    Via the links below you will find more information about deleting cookies per browser:
                                </p>
                                <p>
                                    <a
                                        href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen">Firefox</a>,
                                    <a href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac">Safari</a>,
                                    <a
                                        href="https://support.google.com/chrome/answer/95647?hl=nl&co=GENIE.Platform%3DiOS&oco=1">Chrome</a>,
                                    <a
                                        href="https://support.microsoft.com/nl-nl/microsoft-edge/cookies-verwijderen-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft
                                        Edge</a>,
                                    <a
                                        href="https://support.mozilla.org/nl/kb/cookies-privegegevens-geschiedenis-instellingen-wissen">Firefox
                                        (mobile)</a>,
                                    <a href="https://support.apple.com/nl-be/HT201265">Safari (mobile)</a>
                                </p>
                                <p>
                                    More information about cookies and their removal can be found at <a href="https://www.allaboutcookies.org" target="_blank" rel="noreferrer">www.allaboutcookies.org</a>, or
                                    surf to <a href="https://www.youronlinechoices.eu" target="_blank" rel="noreferrer">www.youronlinechoices.eu</a>
                                    where behavioral advertising and online
                                    privacy are further explained. These websites exist in English only and are not affiliated
                                    with our website or company.
                                </p>
                                <h4>
                                    7. Your rights
                                </h4>
                                <p>
                                    You have the right to access the information held about you and to receive a copy of the
                                    information we hold. If your personal data has changed, you have the right to ask us to
                                    correct, supplement or delete any outdated, inaccurate or incomplete data we hold about you.
                                    You also have the right to restrict the processing of your data and to object to the
                                    processing of your personal data. You also have the right to receive the personal data
                                    concerning you and which you have provided to a controller in a structured, commonly used
                                    and machine-readable format and to transmit that data to another controller.
                                </p>
                                <p>
                                    If you have any questions about this cookie policy or how we process your personal data via
                                    the cookies or if you wish to exercise your rights, you can contact us at
                                    dpo@vansteelandt.be.
                                </p>
                                <p>
                                    You have the right to lodge a complaint with the Data Protection Authority if you are not
                                    satisfied with our response to your requests. If you would like more general information
                                    about the processing of your personal data or about your rights and how to exercise them,
                                    please visit our <Link to={ROUTES.privacyPolicy.path}> privacy policy </Link>.
                                </p>
                                <h4>
                                    8. Changes to this cookie policy
                                </h4>
                                <p>
                                    We may amend and update this policy from time to time to ensure that the policy reflects
                                    which cookies are used and how we process your personal data at any given time. The updated
                                    version of this policy will be posted on the same web page and will be effective immediately
                                    upon publication. Please return to this webpage regularly to ensure that you are always
                                    aware of the information we collect and process, how and under what circumstances your data
                                    is used and in which cases we share your data with third parties.<br />
                                </p>
                                <p>
                                    If you would like more information about how we process personal data and the use of our
                                    website, please consult our <Link to={ROUTES.privacyPolicy.path}> privacy policy </Link>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
    )};
}

export default CookiePolicy
