import React, { Component, Suspense, lazy } from 'react';
import { withRouter } from 'react-router-dom';
import Section from './sections/section';
import { Link } from 'react-router-dom';
import { ROUTES } from '../consts';

class JobDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobRole: props.match.params.jobId || '', 
        }
    }

    render() {
        // Haal het jobId uit de URL-parameters via this.props.match.params
        const { jobId } = this.props.match.params;
        const pdfUrl = `/pdf/vacatures/${jobId}.pdf`; // Pad naar de PDF
        const jobTitle = jobId.replace(/_/g, ' ').trim();

        // Dynamische import voor de vacaturebeschrijving component
        const VacatureDescriptionComponent = lazy(() => import(`../components/vacatures/${jobId}.js`).catch(() => ({ default: () => <p>Vacaturebeschrijving niet beschikbaar.</p> })));

        return (
            <>
            <br/>
            {/* Knop om naar solliciteer formulier te gaan */}
            <div className="text-center mt-4">
                <Link to={`${ROUTES.solliciteerDetail.to}${jobId}`} className="btn btn-dark">
                    Solliciteer voor deze functie
                </Link>
            </div>
            <Section centered={false} title={jobTitle} subtitle={<span className="h6">
                <Suspense fallback={<p>Vacature wordt geladen...</p>}>
                    <VacatureDescriptionComponent />
                </Suspense>
            </span>}>
   
            {/* Knop om naar de PDF te gaan */}
            <div className="text-center mt-4">
                <a href={pdfUrl} target="_blank" rel="noopener noreferrer" className="btn btn-light">
                    Open PDF
                </a>
            </div>
            {/* Knop om naar solliciteer formulier te gaan */}
            <div className="text-center mt-4">
                <Link to={`${ROUTES.solliciteerDetail.to}${jobId}`} className="btn btn-dark">
                    Solliciteer voor deze functie
                </Link>
            </div>
            <br/>
            </Section>
            </>
        );
    }
}

// Gebruik withRouter om toegang te krijgen tot de router-parameters
export default withRouter(JobDetail);
