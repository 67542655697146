import React, {Component} from 'react'
import Section from '../components/sections/section';
import ImageSection from '../components/sections/image-section';

class ServiceDetailWarmtebeelden extends Component {
    render() { return (
        <>
            <Section title="Warmtebeelden / Thermografie (IR)" subtitle={<span className="h6">
                Er worden veel inspanningen geleverd om nieuwe energie te produceren en energieverlies te
                voorkomen. Thermografische kartering
                is een perfecte manier om warmte- en energieverlies van gebouwen in kaart te brengen, te
                analyseren en te visualiseren. <br/>
                Vansteelandt heeft een eigen thermische camera op topniveau die kan worden gebruikt in diverse
                domeinen zoals het in kaart
                brengen van energieverliezen, elektronica inspectie, auto-industrie, hotspot detectie,
                scheurdetectie en inspectie van zonnecellen.
                </span>}
                fullWidth={true} accent={false} fill={true}>
                <ImageSection 
                    images={[
                        {Source: `${process.env.PUBLIC_URL}/img/content/webservice/thermo2.jpg`, Alt:'Landmeter Netwerk'},
                    ]}
                    sectionId='webservice'
                    flip={false} accent={false} vertical={true} heightClass="image-section" className="mb-5 min-h-100">
                    <span id="webservice" className="scroll-fix-item"></span>
                </ImageSection>
            </Section>
        </>
    )};
}

export default ServiceDetailWarmtebeelden
