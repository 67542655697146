import React, { Component } from 'react';

class ImageCarousel extends Component {
    render() { 
        /* Set slides and indicators */
        let id = `image-carousel-${this.props.id}`
        let imageSlides = [], indicators = [];
        for (const [idx, imageObj] of this.props.images.entries()) {
            const activeClass = idx === 0 ? 'active' : '';
            let indicator = <li data-target={`#${id}`} data-slide-to={idx} className={activeClass} key={`ind-${idx}`}></li>
            let imageSlide =    <div className={`carousel-item ${activeClass}`} key={`slide-${idx}`}>
                                    <img className="d-block w-100" src={imageObj.Source} alt={imageObj.Alt} />
                                </div>
            indicators.push(indicator);
            imageSlides.push(imageSlide);
        }

        /* Set controls */
        const controls = this.props.images.length > 1 ? <> 
            <a className="carousel-control-prev" href={`#${id}`} role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href={`#${id}`} role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </> : <> </>;

        indicators = this.props.images.length > 1 ? indicators : <></>;
        const interval = this.props.interval ? this.props.interval : 3000;
        return (
        <div id={id} className="carousel slide carousel-fade" data-ride="carousel" data-interval={interval}>
            <ol className="carousel-indicators">
                {indicators}
            </ol>
            <div className="carousel-inner" role="listbox">
                {imageSlides}
            </div>
            {controls}
        </div>
    )}
}

export default ImageCarousel
