
import React from 'react';
import JobCard from './job-card';
import { Link } from 'react-router-dom';
import { ROUTES } from '../consts';


const JobCards = () => {
    function importAll(r) {
        return r.keys().map(r);
    }
    const ext = 'pdf';
    const vacatures = importAll(require.context('../../public/pdf/vacatures', false, /\.(pdf)$/));
    let cards = [];
    if (vacatures.length) {
        // There are open vacancies
        for(let i = 0; i < vacatures.length; i++) {
            const f = vacatures[i];
            const start = f.lastIndexOf("/")+1;
            const end = f.indexOf(".");
            const file = f.slice(start, end);
            const name = file.replace(/_/g, ' ').replace('Vacature', '').replace('vacature', '').trim();
            
            /*
            cards.push(        
            <div key={i} className="row justify-content-center mb-5">
                <div className="col-12 col-md-6 px-4 mb-4 mb-md-0">
                    <JobCard title={name} location="Deinze" action={`\\pdf\\vacatures\\${file}.${ext}`}></JobCard>
                </div>
            </div>);
            */

            // Link naar de tussenpagina
            cards.push(        
                <div key={i} className="row justify-content-center mb-5">
                    <div className="col-12 col-md-6 px-4 mb-4 mb-md-0">
                        <Link to={`${ROUTES.jobDetail.to}${file}`}>
                            <JobCard title={name} location="Deinze" />
                        </Link>
                    </div>
                </div>
            );

        }    
    }
    else {
        // No open vacancies -> Create text with empty state
        cards.push(
            <div className="row justify-content-center mb-5">
                <div className="col-12 col-md-6 px-4 mb-4 mb-md-0">
                    <h4 className='text-center'>Geen vacatures beschikbaar. Er mag altijd open gesolliciteerd worden via vacatures@vansteelandt.be.</h4>
                </div>
            </div>
        );
    }
    return (
    <>
        {cards}
    </>
)};

export default JobCards    