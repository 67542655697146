import React, { Component } from 'react'
import { ROUTES } from '../consts/index';
import HeaderCarousel from '../components/header-carousel';
import SolutionCard from '../components/solution-card';
// import TestimonialCarousel from '../components/testimonial-carousel';
import AboutVansteelandt from '../components/about-vansteelandt';
import Section from '../components/sections/section';
import JobCards from '../components/job-cards';
import ContactSection from '../components/sections/contact-section';

class Home extends Component {
    /* Add script to page */
    waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
        let startTimeInMs = Date.now();
        (function loopSearch() {
            if (document.querySelector(selector) != null) {
                callback();
                return;
            }
            else {
                setTimeout(function () {
                    if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs)
                        return;
                    loopSearch();
                }, checkFrequencyInMs);
            }
        })();
    }

    componentDidMount() {
        this.waitForElementToDisplay('.customer-logos .slide', () => {
            const jsSlider = document.createElement("script");
            jsSlider.src = "/js/slider.js";
            jsSlider.async = true;
            document.body.appendChild(jsSlider);
        }, 200, 9000);

        // this.waitForElementToDisplay('.count-up', () => {
        //     const jsCounter = document.createElement("script");
        //     jsCounter.src = "/js/counter-animation.js";
        //     jsCounter.async = true;
        //     document.body.appendChild(jsCounter);
        // }, 200, 9000);

        const jsCarousel = document.createElement("script");
        jsCarousel.src = "/js/carousel.js";
        jsCarousel.async = true;
        document.body.appendChild(jsCarousel);
    }

    render() {
        return (
            <div data-spy="scroll" data-target="#navbarMenu" >
                <header>
                    <span id="home" className="scroll-fix-item"></span>
                    <HeaderCarousel />
                </header>

                <Section title="Over Vansteelandt" fill={true} accentDark={true}
                    image={`${process.env.PUBLIC_URL}/img/sections/2planes_airport.jpg`}
                    overlayClass="rgba-black-light" blur={true}>

                    <span id="about" className="scroll-fix-item"></span>

                    <AboutVansteelandt 
                        frameTitle={'Plan3D Teaser'}
                        link={`https://www.youtube.com/embed/rhC8oWqtjBc?rel=0`}>
                    </AboutVansteelandt>
                </Section>

                <Section title="Smart Survey Services" fill={true} accentDark={true}
                    image={`${process.env.PUBLIC_URL}/img/sections/plan3dbg.jpg`}
                    overlayClass="rgba-black-light" blur={true}>
                    <span id="services" className="scroll-fix-item"></span>
                    <div className="container">
                        <div className="row wow fadeIn my-auto justify-content-center">
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="meten_michael.jpg" title="Landmeter Netwerk" action={ROUTES.serviceDetailLandmeterNetwerk} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="pc_lakenhallen.jpg" title="Plan3D"  action="https://plan3d.be/" />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="3dscanbg.jpg" title="3D Laserscanning" action={ROUTES.serviceDetail3DLaserscanning} />
                            </div>
                        </div>

                        <div className="row wow fadeIn my-auto justify-content-center mt-4">
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="thermo2.jpg" title="Warmtebeelden" action={ROUTES.serviceDetailWarmtebeelden} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="gis.jpg" title="CAD & GIS" action={ROUTES.serviceDetailCADGIS} />
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 px-2 mb-4 mb-md-0">
                                <SolutionCard image="bestel.jpg" title="Bestel uw terreinopmeting" action="https://plan3d.be/services/order" />
                            </div>
                        </div>
                    </div>
                </Section>

                {/* <Section title="Reviews" subtitle="">
            <span id="clients" className="scroll-fix-item"></span>
            <div className="text-center dark-grey-text">
                <TestimonialCarousel />
            </div>
        </Section>
        <hr className="my-2" />*/}


                <Section title="Jobs" fill={false} fullWidth={true} accent={false}>
                    <span id="jobs" className="scroll-fix-item"></span>
                    <JobCards />
                </Section>

                <ContactSection />
            </div>
        )
    };
}

export default Home
