import React from 'react';

const AboutVansteelandt = ({ contentTitle, contentText, link, accent, frameTitle }) => {
    return (
        <div className={`${accent ? "section-accent" : ""} w-100`}>
            <div className="container">
                <div className="row no-gutters justify-content-center">
                    <div className="col-md-12 col-lg-12">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                title={frameTitle}
                                className="embed-responsive-item"
                                src={link}
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters justify-content-center mt-4">
                    <div className="col-md-12 col-lg-6r">
                        <div className="p-4">
                            {contentTitle ? <h3 className="font-weight-normal mb-4">{contentTitle}</h3> : null}
                            <p>
                                {/*contentText*/}
                                Wij zijn specialisten in het uitvoeren van detail- en accurate 3D opmetingen. <br></br>
                                In veel gevallen zal een opmeting een combinatie zijn van verschillende complementaire
                                technologieën. <br></br>
                                Ons doel is om de klant de best mogelijke oplossing te geven. Onze klanten hebben nood aan
                                actuele informatie
                                betreffende de werkelijke situatie. Wij zoeken de meest kost-efficiënte manier om de nodige
                                (Geo-)
                                informatie te verzamelen en verwerken deze tot het meest geschikte eindproduct in een formaat
                                dat
                                onze klant het best kan gebruiken voor zijn specialisatie
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row no-gutters justify-content-center mt-4">
                    <div className="col-sm-4">
                        <div className="p-4">
                            <h4>Wat meten wij?</h4>
                                <li>Objecten, inventarisatie</li>
                                <li>Gebouwen en constructies</li>
                                <li>Industriële sites</li>
                                <li>Terreinen</li>
                                <li>Wegen en andere transportnetwerken</li>
                                <li>Grotere gebieden tot zelfs gemeentes, provincies, ...</li>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="p-4">
                            <h4>Hoe meten wij?</h4>
                                <li>Traditionele landmeetkunde: (RTK) GPS, Total Stations</li>
                                <li>3D laserscanning: statisch, vanop een mobile platform alsook vanuit de lucht</li>
                                <li>3D fotoprocessing, Thermisch Infra-Rood (IR), (360°) video oplossingen</li>
                                <li>Scanning oplossingen vanuit de lucht (UAV, helikopter, vliegtuig)
                                </li>
                                <li>Inspecties en monitoring: positiebepaling, image processing, IR</li>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="p-4">
                            <h4>Welke producten leveren wij?</h4>
                                <li>CAD: 2D, 3D, 3D Revit/BIM</li>
                                <li>GIS: aanmaak/update</li>
                                <li>Foto/video producten en rapporten</li>
                                <li>Orthomosaics</li>
                                <li>DSM/DTM</li>
                                <li>3D Mesh</li>
                                <li>3D Virtual Reality</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutVansteelandt;
